import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-simple-tri-state-button-value-accessor',
  templateUrl: './simple-tri-state-button-value-accessor.component.html',
  styleUrls: ['./simple-tri-state-button-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleTriStateButtonValueAccessorComponent),
      multi: true,
    }
  ],
})
export class SimpleTriStateButtonValueAccessorComponent extends AbstractControlComponent {
  @Input() label;
  @Input() required = false;

  valueForm: FormControl;

  constructor(private _formBuilder: FormBuilder) {
    super();
    this.valueForm = this._formBuilder.control(null);
  }

  ngOnInit(): void {
  }

  writeValue(value: any) {
    this.valueForm.setValue(value);
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.valueForm.disable() : this.valueForm.enable();
  }

  registerOnChange(fn: any) {
    this.valueForm.valueChanges.subscribe(fn);
  }


}
