import {IContract} from './interface/contract.model.interface';
import {TotalCosts} from './total-costs.model';
import {ContractView} from './contract-view.model';
import {IContractType} from './interface/contract-type.model.interface';
import moment, {Moment} from 'moment';
import {ContractItemList} from './contract-item.model';
import {ScheduleList} from "./schedule.model";
import {IContractKind} from "./interface/IContractKind.interface";
import {IContractStatus} from "./interface/contract-status.model.interface";

export abstract class Contract extends ContractView {
  private readonly _totalCosts: TotalCosts;
  private readonly _invoicedCosts: TotalCosts;
  private readonly _settled: boolean;
  private readonly _invoiceable: boolean;
  private readonly _removable: boolean;
  private readonly _items: ContractItemList;
  private readonly _type: IContractType;
  private readonly _kind: IContractKind;
  private readonly _settleDate: Moment;
  private readonly _conclusionDate: Moment;
  private readonly _createdAt: Moment;
  private readonly _bankAccountNumber: string;
  private readonly _schedules: ScheduleList;
  private readonly _terminationDate: Moment;
  private readonly _status: IContractStatus;

  protected constructor(instance: IContract) {
    super(instance);
    this._totalCosts = new TotalCosts(instance.totalCosts);
    this._invoicedCosts = new TotalCosts(instance.invoicedCosts);
    this._settled = instance.settled;
    this._invoiceable = instance.invoiceable;
    this._removable = instance.removable;
    this._type = instance.type;
    this._bankAccountNumber = instance.bankAccountNumber;

    if (instance.items) {
      this._items = new ContractItemList(instance.items);
    }
    if (instance.settleDate) {
      this._settleDate = moment(instance.settleDate);
    }
    if (instance.conclusionDate) {
      this._conclusionDate = moment(instance.conclusionDate);
    }
    if (instance.createdAt) {
      this._createdAt = moment(instance.createdAt);
    }
    if (instance.schedules) {
      this._schedules = new ScheduleList(instance.schedules);
    }
    if (instance.kind) {
      this._kind = instance.kind
    }
    if (instance.terminationDate) {
      this._terminationDate = instance.terminationDate
    }
    if (instance.status) {
      this._status = instance.status;
    }
  }

  get totalCosts(): TotalCosts {
    return this._totalCosts;
  }

  get invoicedCosts(): TotalCosts {
    return this._invoicedCosts;
  }

  get settled(): boolean {
    return this._settled;
  }

  get invoiceable(): boolean {
    return this._invoiceable;
  }

  get removable(): boolean {
    return this._removable;
  }

  get kind(): IContractKind {
    return this._kind;
  }

  get terminationDate(): Moment {
    return this._terminationDate;
  }

  get bankAccountNumber(): string {
    return this._bankAccountNumber;
  }

  get items(): ContractItemList {
    return this._items;
  }

  get type(): IContractType {
    return this._type;
  }

  get settleDate(): moment.Moment {
    return this._settleDate;
  }

  get conclusionDate(): moment.Moment {
    return this._conclusionDate;
  }

  get createdAt(): moment.Moment {
    return this._createdAt;
  }

  get schedules(): ScheduleList {
    return this._schedules;
  }

  get status(): IContractStatus {
    return this._status;
  }
}
