<h2 mat-dialog-title>Import Spraw komorniczych</h2>
<mat-dialog-content>
  <mat-stepper linear #stepper>
    <form [formGroup]="importBailiffFormGroup">
      <mat-step [stepControl]="file"
                label="Plik z sprawami komorniczymi Gps">
        <ng-template matStepContent>
          <mat-card class="width-100 mat-elevation-z0">
            <mat-card-content>
              Format pliku excel:
              <p> Oznaczenie wg Powoda(EPU) - sygnaura komornicza (opcjonalne) - wartość zaliczki komorniczej</p>
              <app-import-payments-select-file formControlName="file"
                                               class="width-100">
              </app-import-payments-select-file>

              <app-simple-search-select-value-accessor label="Wybierz komornika"
                                                       minLength="0"
                                                       [searchFunction]="searchBailiffsFunction"
                                                       (selected)="setBailiff($event)">
              </app-simple-search-select-value-accessor>

              <app-simple-search-select-value-accessor label="wybierz sąd"
                                                       minLength="0"
                                                       [searchFunction]="searchCourtsFunction"
                                                       (selected)="setCourt($event)">
              </app-simple-search-select-value-accessor>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button
                      action-button
                      color="primary"
                      matStepperNext
                      [disabled]="importBailiffFormGroup.invalid"
                      [action]="importBailiff.bind(this)">
                Załaduj sprawy komornicze
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-template>
      </mat-step>
    </form>
  </mat-stepper>
</mat-dialog-content>
