<mat-dialog-content>
  <app-remark-searcher-view title="Wyszukiwanie uwag"
                            #view></app-remark-searcher-view>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button
          color="primary"
          class="width-100"
          (click)="export(form.value)"
          [disabled]="!formValid">
    Wyeksportuj
  </button>
</mat-dialog-actions>
