<h1 class="primary-color">{{title}}</h1>
<div class="container">
  <form [formGroup]="searchFormGroup">
    <app-search-by-date-from-to label="Data utworzenia alertu w systemie"
                                formControlName="createdAt"></app-search-by-date-from-to>
    <app-search-by-date-from-to label="Data utworzenia sprawy w systemie"
                                formControlName="legalCaseCreatedAt"></app-search-by-date-from-to>
    <h3 class="primary-color">Prowadzący</h3>
    <app-search-vindicator formControlName="vindicatorId"></app-search-vindicator>
    <app-search-field-attorney formControlName="fieldAttorneyId"></app-search-field-attorney>

    <h3 class="primary-color">Zlecający</h3>
    <ng-container *appIfRoleIn="['ADMIN', 'FIELD_ATTORNEY', 'VINDICATOR', 'POST_SECRETARY']">
      <app-search-customer label="Zlecający" formControlName="instructingId"></app-search-customer>
      <app-search-lessor-group formControlName="instructingGroupId"></app-search-lessor-group>
    </ng-container>

    <h3 class="primary-color">Dłużnik</h3>
    <app-searcher-customer label="Dłużnik" formControlName="debtorCoreId"></app-searcher-customer>
    <app-simple-string-value-accessor label="NIP" formControlName="debtorNIP"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Numer klienta"
                                      formControlName="debtorNumber"></app-simple-string-value-accessor>
  </form>
</div>
