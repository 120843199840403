import {IContractItem, IContractItemPropertyList} from './interface/contract-item.model.interface';
import {Serializable} from '../interface/serializable.interface';
import {StringJoiner} from '../joiner/string.joiner';
import {ParkingLot} from './parking-lot.model';
import {ObjectList} from './interface/object-list.model.interface';
import {IContractItemType} from './interface/contract-item-type.model.interface';
import {ContractItemPropertyHelper} from '../helper/contract-item-property.helper';
import {TotalCosts} from './total-costs.model';
import {Money} from './money.model';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {PaInfo} from './pa-info.model';
import {WmInfo} from './wm-info.model';
import {IStatus} from "./interface/contract-status.model.interface";

export class ContractItem implements Serializable {
  private readonly _id: number;
  private readonly _type: IContractItemType;
  private readonly _properties: IContractItemPropertyList;
  private readonly _released: boolean;
  private readonly _targetParkingLot: ParkingLot;
  private readonly _transitionalParkingLot: ParkingLot;
  private readonly _totalCosts: TotalCosts;
  private readonly _invoicedCosts: TotalCosts;
  private readonly _marketValue: Money;
  private readonly _paInfo: PaInfo;
  private readonly _wmInfo: WmInfo;
  private readonly _gpsLatitude: number;
  private readonly _gpsLongitude: number;
  private readonly _gpsTimestamp: Date;
  private readonly _status: IStatus;

  constructor(instance: IContractItem) {
    this._id = instance.id;
    this._type = instance.type;
    this._properties = instance.properties;
    this._released = instance.released;
    // this._totalCosts = new TotalCosts(instance.totalCosts);
    // this._invoicedCosts = new TotalCosts(instance.invoicedCosts);
    if (instance.paInfo) {
      this._paInfo = new PaInfo(instance.paInfo);
    }
    if (instance.wmInfo) {
      this._wmInfo = new WmInfo(instance.wmInfo);
    }
    if (instance.targetParkingLot) {
      this._targetParkingLot = new ParkingLot(instance.targetParkingLot);
    }
    if (instance.transitionalParkingLot) {
      this._transitionalParkingLot = new ParkingLot(instance.transitionalParkingLot);
    }
    if (instance.marketValue) {
      this._marketValue = new Money(instance.marketValue);
    }
    if (instance.gpsLatitude) {
      this._gpsLatitude = instance.gpsLatitude;
    }
    if (instance.gpsLongitude) {
      this._gpsLongitude = instance.gpsLongitude;
    }
    if(instance.gpsTimestamp) {
      this._gpsTimestamp = instance.gpsTimestamp
    }
    if(instance.status) {
      this._status = instance.status;
    }
  }

  get paInfo(): PaInfo {
    return this._paInfo;
  }

  get wmInfo(): WmInfo {
    return this._wmInfo;
  }

  get id(): number {
    return this._id;
  }

  get type(): IContractItemType {
    return this._type;
  }

  get properties(): IContractItemPropertyList {
    return this._properties;
  }

  get marketValue(): Money {
    return this._marketValue;
  }

  get totalCosts(): TotalCosts {
    return this._totalCosts;
  }

  get invoicedCosts(): TotalCosts {
    return this._invoicedCosts;
  }

  get targetParkingLot(): ParkingLot {
    return this._targetParkingLot;
  }

  get transitionalParkingLot(): ParkingLot {
    return this._transitionalParkingLot;
  }

  get released(): boolean {
    return this._released;
  }

  get latitude(): number {
    return this._gpsLatitude
  }

  get longitude(): number {
    return this._gpsLongitude
  }
  get gpsTimestamp(): Date {
    return this._gpsTimestamp
  }
  get status(): IStatus {
    return this._status;
  }

  toText() {
    const joiner = new StringJoiner('\n');
    joiner.append(this._type.label);
    joiner.append(ContractItemPropertyHelper.serializeList(this._properties));
    joiner.append(this.serializeMarketValue());
    return joiner.join();
  }

  private serializeMarketValue() {
    const title = 'Wartość przedmiotu';
    if (this._marketValue) {
      return `${title}: ${this._marketValue.toText()}`;
    }
  }

  toHTML(): string {
    const joiner = new StringJoiner('<br>');
    joiner.append(this._type.label);
    joiner.append(ContractItemPropertyHelper.serializeListToHTML(this._properties));
    joiner.append(this.serializeMarketValue());
    return joiner.join();
  }
}

export class ContractItemList extends ObjectList<ContractItem, IContractItem> {
  protected mapToListElement(instance: IContractItem): ContractItem {
    return new ContractItem(instance);
  }

  protected mapToSelectableItem(instance: ContractItem): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.toText()
    };
  }

  public static fromArray(data: ContractItem[]) {
    const list = new ContractItemList({
      data: [],
      totalCount: data.length
    });
    list.data = data;
    return list;
  }
  public toArray(): ContractItem[] {
    return this.data;
  }
}
