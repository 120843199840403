import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export interface ICourt {
  id: number;
  name: string;
}


export class ICourtList extends ObjectList<ICourt, ICourt> {
  protected mapToListElement(instance: ICourt): ICourt {
    return instance;
  }

  protected mapToSelectableItem(instance: ICourt): ISimpleSelectableData {
    return {
      label: instance.name,
      value: instance.id
    };
  }
}
