import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IContractItemTypeList} from '../../shared/model/interface/contract-item-type.model.interface';
import {IEditContractItem} from '../../shared/model/interface/edit-contract-item.model.interface';
import {INewParkingLot} from '../../shared/model/interface/new-parking-lot.model.interface';
import {INewContractItem} from '../../shared/model/interface/new-contract-item.model.interface';
import {WebsocketService} from './websocket.service';
import {ContractItemObservableMapper} from '../../shared/mapper/contract-item-observable.mapper';
import {IObjectList} from '../../shared/model/interface/object-list.model.interface';
import {IContractItem} from '../../shared/model/interface/contract-item.model.interface';
import {map} from 'rxjs/operators';
import {ContractItemList} from '../../shared/model/contract-item.model';
import {IContractItemTypePropertyList} from "../../shared/model/interface/contract-item-type-property.model.interface";
import {IContractStatusList, IStatus} from "../../shared/model/interface/contract-status.model.interface";

@Injectable({
  providedIn: 'root'
})
export class ContractItemService {
  constructor(private http: HttpClient,
              private contractItemObservableMapper: ContractItemObservableMapper,
              private websocketService: WebsocketService) {
  }

  private URL = environment.backendUrl;

  connectToWebsocket(itemId: number) {
    const topic = this.websocketService.subscribeToContractItem(itemId);
    return this.contractItemObservableMapper.fromWebsocketMessage(topic);
  }

  create(contractId: number, newItem: INewContractItem) {
    return this.http.post(this.URL + `/contract-items/contracts/${contractId}/create`, newItem);
  }

  getAllAvailableContractItemTypes() {
    return this.http.get<IContractItemTypeList>(this.URL + '/contract-items/types');
  }

  getAllAvailableContractItemStatuses() {
    return this.http.get<IStatus[]>(this.URL + '/contract-item/statuses');
  }

  getAllAvailableContractItemPropertyTypes() {
    return this.http.get<IContractItemTypePropertyList>(this.URL + '/contract-items/properties/types').pipe(
      map(data => new IContractItemTypePropertyList(data))
    );
  }


  loadMarketValues(file: File, instructingId: number) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put(this.URL + `/load-market-values/customers/${instructingId}`, formData);
  }

  updateItem(itemId: number, editedContractItem: IEditContractItem) {
    return this.http.put(this.URL + `/contract-items/${itemId}/update`, editedContractItem);
  }

  applyItemAbroad(itemId: number) {
    return this.http.put(this.URL + `/contract-items/${itemId}/apply/item-abroad`, null);
  }

  applyTransitionalParkingLot(itemId: number, parkingLot: INewParkingLot) {
    return this.http.put(this.URL + `/contract-items/${itemId}/apply/transitional-parking-lot`, parkingLot);
  }

  applyTargetParkingLot(itemId: number, parkingLot: INewParkingLot) {
    return this.http.put(this.URL + `/contract-items/${itemId}/apply/target-parking-lot`, parkingLot);
  }

  delete(itemId: number) {
    return this.http.delete(this.URL + `/contract-items/${itemId}/delete`);
  }

  removeTransitionalParkingLot(contractItemId: number) {
    return this.http.delete(this.URL + `/contract-items/${contractItemId}/transitional-parking-lots/delete`);
  }

  removeTargetParkingLot(contractItemId: number) {
    return this.http.delete(this.URL + `/contract-items/${contractItemId}/target-parking-lots/delete`);
  }

  moveItemToWm(itemId: number) {
    return this.http.post<void>(this.URL + `/contract-items/${itemId}/move-to-wm`, null);
  }

  moveItemToPa(itemId: number) {
    return this.http.post<void>(this.URL + `/contract-items/${itemId}/move-to-pa`, null);
  }

  fetchAllForLegalCase(legalCaseId: number) {
    return this.http.get<IObjectList<IContractItem>>(this.URL + `/legal-cases/${legalCaseId}/contract-items`)
      .pipe(
        map(items => new ContractItemList(items))
      );
  }
}
