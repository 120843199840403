import {IRemark} from './interface/remark.model.interface';
import {User, UserList} from './user.model';
import {ObjectList} from './interface/object-list.model.interface';
import {Moment} from 'moment';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class Remark {
  private readonly _content: string;
  private readonly _author: User;
  private readonly _addressees: UserList;
  private readonly _createdAt: Moment;

  constructor(instance: IRemark) {
    this._content = instance.content;
    this._author = new User(instance.author);
    this._addressees = new UserList(instance.addressees);
    this._createdAt = instance.createdAt;
  }

  get content(): string {
    return this._content;
  }

  get author(): User {
    return this._author;
  }

  get addressees(): UserList {
    return this._addressees;
  }

  get createdAt(): moment.Moment {
    return this._createdAt;
  }
}

export class RemarkList extends ObjectList<Remark, IRemark> {
  protected mapToListElement(instance: IRemark): Remark {
    return new Remark(instance);
  }

  protected mapToSelectableItem(instance: Remark): ISimpleSelectableData {
    return undefined;
  }
}
