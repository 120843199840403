<app-header></app-header>
<app-loader></app-loader>
<mat-sidenav-container class="container">
  <mat-sidenav
    mode="over"
    [(opened)]="isOpened"
    [autoFocus]="false"
    style="background-color: white;"
    (opened)="sidenavOpened()"
    (closed)="sidenavClosed()"
    (click)="sidenavClosed()"
  >
    <mat-toolbar>
      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('home')}">
        <button mat-button class="sidenav-button" (click)="gotoMainWindow()">
          <mat-icon>dashboard</mat-icon>
          <span>Widok główny</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('search')}">
        <button mat-button class="sidenav-button" (click)="gotoSearchWindow()">
          <mat-icon>search</mat-icon>
          <span>Wyszukaj sprawę</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('search')}">
        <button mat-button class="sidenav-button" (click)="gotoRemarkSearchWindow()">
          <mat-icon>notification_important</mat-icon>
          <span>Wyszukaj uwagi</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('my-account')}">
        <button mat-button class="sidenav-button" [autofocus]="false" (click)="gotoMyAccount()">
          <mat-icon>account_box</mat-icon>
          <span>Moje konto</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('post')}" *appIfPermission="'OUTBOX_R'">
        <button mat-button class="sidenav-button" [autofocus]="false" (click)="gotoPost()">
          <mat-icon>markunread_mailbox</mat-icon>
          <span>Poczta</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row *appIfRoleIn="['ADMIN']"
                       [ngClass]="{'selected': this.router.url.includes('admin')}">
        <button mat-button class="sidenav-button" (click)="gotoAdminPanel()">
          <mat-icon>admin_panel_settings</mat-icon>
          <span>Panel administratora</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('court')}" *appIfPermission="'COURT_R'">
        <button mat-button class="sidenav-button" [autofocus]="false" (click)="gotoCourt()">
          <mat-icon>markunread_mailbox</mat-icon>
          <span>Sąd</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('strategies')}">
        <button mat-button class="sidenav-button" (click)="gotoStrategyPanel()">
          <mat-icon>dynamic_feed</mat-icon>
          <span>Strategie </span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row [ngClass]="{'selected': this.router.url.includes('manage')}">
        <button mat-button class="sidenav-button" (click)="gotoManagePanel()">
          <mat-icon>assignment</mat-icon>
          <span>Zarządzanie </span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <button mat-button class="sidenav-button" (click)="goToEventsWindow()">
          <mat-icon>description</mat-icon>
          <span> Zdarzenia </span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <button mat-button class="sidenav-button" (click)="goToReportWindow()">
          <mat-icon>summarize</mat-icon>
          <span> Raport okresowy </span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <button mat-button class="sidenav-button" (click)="toggleNestedToolbar($event)">
          <mat-icon>import_export</mat-icon>
          <span> Import </span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row class="import-row" *ngIf="isImportTypesVisible">
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importPayments()">
          <mat-icon>account_balance_wallet</mat-icon>
          <span>Import wpłat</span>
        </button>
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importValuations()">
          <mat-icon>price_check</mat-icon>
          <span>Import wycen</span>
        </button>
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importVerification()">
          <mat-icon>groups</mat-icon>
          <span>Import weryfikacji klientów</span>
        </button>
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importEpu()">
          <mat-icon>gavel</mat-icon>
          <span>Import Epu</span>
        </button>
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importBailiff()">
          <mat-icon>monetization_on </mat-icon>
          <span>Import spraw komorniczych</span>
        </button>
        <button *appIfRoleIn="['ADMIN']" mat-button class="sidenav-button" (click)="importGps()">
          <mat-icon>location_on</mat-icon>
          <span>Import Gps</span>
        </button>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <button mat-button class="sidenav-button" (click)="gotoForm()">
          <mat-icon>tips_and_updates</mat-icon>
          <span>Zgłoś błąd lub zaproponuj zmianę </span>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <span>ver:</span>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet class="router-flex"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<span class="copyrights">{{ copyrights }}</span>
