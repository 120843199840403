import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {IObjectList} from "../../shared/model/interface/object-list.model.interface";
import {map} from "rxjs/operators";
import {IBailiff, IBailiffList} from "../../shared/model/interface/bailiff.model.interface";

@Injectable({
  providedIn: 'root'
})
export class BailiffService {
  private static readonly _URL = environment.backendUrl + '/bailiffs';

  constructor(private _httpClient: HttpClient) {
  }

  search(name: string, page: number, size: number) {
    return this._httpClient.get<IObjectList<IBailiff>>(BailiffService._URL, {
      params: {
        name,
        page,
        size
      }
    })
      .pipe(
        map(data => new IBailiffList(data))
      );
  }

}
