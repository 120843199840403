import {Contract} from './contract.model';
import {ObjectList} from './interface/object-list.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {IDpdContract} from './interface/dpd-contract.model.interface';
import {MoneyList} from './money.model';
import {LegalEntityList} from './legal-entity.model';
import {CustomerFactory} from '../factory/customer.factory';

export class DpdContract extends Contract {
  private _effectiveDebt: MoneyList;
  private _initialDebt: MoneyList;
  private _paymentSum: MoneyList;
  private _guarantors: LegalEntityList;

  constructor(instance: IDpdContract) {
    super(instance);
    this._effectiveDebt = new MoneyList(instance.effectiveDebt);
    this._initialDebt = new MoneyList(instance.initialDebt);
    this._paymentSum = new MoneyList(instance.paymentSum);
    this._guarantors = CustomerFactory.fromList(instance.guarantors);
  }

  set effectiveDebt(value: MoneyList) {
    this._effectiveDebt = value;
  }

  get effectiveDebt(): MoneyList {
    return this._effectiveDebt;
  }

  get initialDebt(): MoneyList {
    return this._initialDebt;
  }

  get paymentSum(): MoneyList {
    return this._paymentSum;
  }

  get guarantors(): LegalEntityList {
    return this._guarantors;
  }
}

export class DpdContractList extends ObjectList<DpdContract, IDpdContract> {
  protected mapToListElement(instance: IDpdContract): DpdContract {
    return new DpdContract(instance);
  }

  protected mapToSelectableItem(instance: DpdContract): ISimpleSelectableData {
    return {
      label: instance.number,
      value: instance.id
    }
  }
}
