import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EventBusService} from '../../../core/services/event-bus.service';
import {ImporterService} from '../../../core/services/importer.service';
import {MatDialogRef} from '@angular/material/dialog';
import {map, tap} from 'rxjs/operators';
import {DictionaryPosition} from '../../model/dictionary.model';
import {Observable} from 'rxjs';
import {IBailiffImport} from '../../model/interface/bailiff-import.interface.model';
import {BailiffService} from '../../../core/services/bailiff.service';
import {CourtService} from '../../../core/services/court.service';
import {ISimpleSelectableData} from '../../model/interface/simple-selectable-data.model.interface';

@Component({
  selector: 'app-import-bailiff',
  templateUrl: './import-bailiff.component.html',
  styleUrls: ['./import-bailiff.component.scss']
})
export class ImportBailiffComponent implements OnInit {
  importBailiffFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private bailiffService: BailiffService,
              private courtService: CourtService,
              private _dialogRef: MatDialogRef<ImportBailiffComponent>) {
    this._createForm();
  }

  ngOnInit(): void {
  }

  importBailiff() {
    const payload = this._createImportBailiffPayload();
    const request = this._createImportBailiffRequest(payload);
    return this._handleRequest(request);
  }

  public searchBailiffsFunction = (bailiffName: string, page: number, size: number) => {
    return this._createFetchBailiffsRequest(bailiffName, page, size);
  }

  private _createFetchBailiffsRequest(bailiffName: string, page: number, size: number) {
    return this.bailiffService.search(bailiffName, page, size)
      .pipe(
        map(bailiffs => bailiffs.toSimpleSelectableDataList())
      );
  }

  setBailiff(bailiff: ISimpleSelectableData) {
    this.importBailiffFormGroup.controls.bailiffId.setValue(bailiff.value);
  }

  setCourt(court: ISimpleSelectableData) {
    this.importBailiffFormGroup.controls.courtId.setValue(court.value);
  }

  private _createForm() {
    this.importBailiffFormGroup = this._formBuilder.group({
      file: [null, Validators.required],
      bailiffId: [null, Validators.required],
      courtId: [null, Validators.required]
    })
  }

  get file() {
    return this.importBailiffFormGroup.get('file') as FormControl;
  }

  private _createImportBailiffRequest(payload: IBailiffImport) {
    return this._importerService.importBailiff(payload)
      .pipe(
        tap(_ => this._eventBusService.display(DictionaryPosition.OK))
      )
  }

  private _createImportBailiffPayload(): IBailiffImport {
    return {
      file: this.importBailiffFormGroup.value.file,
      bailiffId: this.importBailiffFormGroup.value.bailiffId,
      courtId: this.importBailiffFormGroup.value.courtId,
    } as IBailiffImport
  }

  public searchCourtsFunction = (courtName: string, page: number, size: number) => {
    return this._createFetchCourtsRequest(courtName, page, size);
  }

  private _createFetchCourtsRequest(courtName: string, page: number, size: number) {
    return this.courtService.search(courtName, page, size)
      .pipe(
        map(courts => courts.toSimpleSelectableDataList())
      );
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }
}
