import {Component, OnInit} from '@angular/core';
import {SidenavService} from '../../services/sidenav.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {User} from 'src/app/shared/model/user.model';
import {LoaderService} from '../../services/loader.service';
import {AuthorizationService} from '../../services/authorization.service';
import {DialogOpener} from '../../../shared/utils/dialog-opener';
import {
  LegalCaseSearcherDialogComponent
} from '../../../shared/dialog/legal-case-searcher-dialog/legal-case-searcher-dialog.component';
import {
  LoadLegalCasePaymentsComponent
} from '../../../shared/legal-case-shared/load-legal-case-payments/load-legal-case-payments.component';
import {STORAGE_KEY, StorageService} from '../../services/storage.service';
import {ImportPaymentsComponent} from '../../../shared/dialog/import-payments/import-payments.component';
import {EventsSummaryComponent} from '../../../shared/dialog/events-summary/events-summary.component';
import {ImportGpsComponent} from '../../../shared/dialog/import-gps/import-gps.component';
import {
  LegalCaseSummaryComponent
} from '../../../shared/dialog/contract-summary-component/legal-case-summary.component';
import {ImportValuationComponent} from '../../../shared/dialog/import-valuation/import-valuation.component';
import {ImportEpuComponent} from '../../../shared/dialog/import-epu/import-epu.component';
import {ImportVerificationComponent} from '../../../shared/dialog/import-verification/import-verification.component';
import {
  RemarkSearcherDialogComponent
} from '../../../shared/dialog/remark-searcher-dialog/remark-searcher-dialog.component';
import {ImportBailiffComponent} from '../../../shared/dialog/import-bailiff/import-bailiff.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isOpened = false;
  authenticatedEmployee: User;
  isImportTypesVisible = false;

  constructor(
    private sidenavStateService: SidenavService,
    public router: Router,
    private loader: LoaderService,
    private storageService: StorageService,
    private dialogOpener: DialogOpener,
    private authorizationService: AuthorizationService,
  ) {
    sidenavStateService
      .isSidenavOpenedSubject()
      .subscribe((isOpened: boolean) => {
        this.isOpened = isOpened;
      });
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.hide();
      }
    });
  }
  toggleNestedToolbar(event: Event){
    event.stopPropagation();
    this.isImportTypesVisible = !this.isImportTypesVisible;
  }
  gotoMainWindow() {
    this.router.navigate(['/home']);
  }

  gotoMyAccount() {
    this.router.navigate(['/my-account']);
  }

  sidenavOpened() {
    this.sidenavStateService.updateSidenavOpened(true);
  }

  sidenavClosed() {
    this.sidenavStateService.updateSidenavOpened(false);
  }

  gotoAdminPanel() {
    this.router.navigate(['/admin']);
  }

  gotoCourt() {
    this.router.navigate(['/court']);
  }

  gotoPost() {
    this.router.navigate(['/post']);
  }

  isAdmin() {
    return this.authorizationService.isAdmin();
  }

  goToEventsWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(EventsSummaryComponent)
      .subscribe();
  }

  goToReportWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(LegalCaseSummaryComponent)
        .subscribe();
  }

  gotoSearchWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(LegalCaseSearcherDialogComponent)
      .subscribe(params => this.router.navigate(['/searcher'], {
        queryParams: params
      }));
  }

  gotoRemarkSearchWindow() {
    this.storageService.remove(STORAGE_KEY.SEARCH_PARAMS);
    this.dialogOpener.getNonNullDialogResponseObservable(RemarkSearcherDialogComponent)
      .subscribe(params => this.router.navigate(['/remarks-searcher'], {
        queryParams: params
      }));
  }

  gotoLoadPayments() {
    this.dialogOpener.open(LoadLegalCasePaymentsComponent);
  }

  get copyrights() {
    const year = new Date().getFullYear();
    return `Copyright ©${year} RJ Digital Management. All Rights Reserved.`;
  }

  gotoStrategyPanel() {
    this.router.navigate(['/strategies']);
  }

  gotoForm() {
    window.open(`https://forms.hive.com/?formId=48h9gFRQLS8hiJGmo`, '_blank');
  }


  importPayments() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportPaymentsComponent, {}).subscribe(_ => {});
  }

  importValuations() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportValuationComponent, {}).subscribe(_ => {});
  }

  importGps() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportGpsComponent, {}).subscribe(_ => {});
  }

  importBailiff() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportBailiffComponent, {}).subscribe(_ => {});
  }

  importEpu() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportEpuComponent, {}).subscribe(_ => {});
  }

  gotoManagePanel() {
    this.router.navigate(['/manage']);
  }

  importVerification() {
    this.dialogOpener.getNonNullDialogResponseObservable(ImportVerificationComponent, {}).subscribe(_ => {});
  }
}
