import {Injectable} from '@angular/core';
import {IMessage} from '@stomp/stompjs';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {WebsocketObservableMapper} from './websocket-observable.mapper';
import {IRemark} from '../model/interface/remark.model.interface';
import {Remark} from '../model/remark.model';

@Injectable({
  providedIn: 'root'
})
export class RemarkObservableMapper implements WebsocketObservableMapper<Remark> {
  fromWebsocketMessage(message: Observable<IMessage>): Observable<Remark> {
    return message.pipe(
      map(messageInstance => JSON.parse(messageInstance.body) as IRemark),
      filter(remark => remark !== null && remark !== undefined),
      map(instance => new Remark(instance))
    );
  }
}
