import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AdminGuard} from './core/services/guards/admin.guard';
import {AuthenticationGuard} from './core/services/guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./features/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard, AuthenticationGuard],
    // canLoad: [AuthenticationGuard]
  },
  {
    path: 'post',
    loadChildren: () =>
      import('./features/post/post.module').then((m) => m.PostModule),
    canActivate: [AuthenticationGuard],
    // canLoad: [AuthenticationGuard]
  },
  {
    path: 'court',
    loadChildren: () =>
      import('./features/court/court.module').then((m) => m.CourtModule),
    canActivate: [AuthenticationGuard],
    // canLoad: [AuthenticationGuard]
  },
  {
    path: 'my-cases',
    loadChildren: () =>
      import('./features/my-cases/my-cases.module').then((m) => m.MyCasesModule),
    canActivate: [AuthenticationGuard],
    // canLoad: [AuthenticationGuard]
  },
  {
    path: 'statistics',
    loadChildren: () =>
      import('./features/statistics/statistics.module').then((m) => m.StatisticsModule),
    canActivate: [AuthenticationGuard],
    // canLoad: [AuthenticationGuard]
  },
  {
    path: 'monitored-payments',
    loadChildren: () =>
      import('./features/monitored-payment/monitored-payment.module').then(
        (m) => m.MonitoredPaymentModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'previndications',
    loadChildren: () =>
      import('./features/previndication/previndication.module').then(
        (m) => m.PrevindicationModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'vindications',
    loadChildren: () =>
      import('./features/vindication/vindication.module').then(
        (m) => m.VindicationModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'initial-verifications',
    loadChildren: () =>
      import('./features/initial-verification/initial-verification.module').then(
        (m) => m.InitialVerificationModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'relocations-and-releases',
    loadChildren: () =>
      import('./features/relocations-and-releases/relocation-and-release.module').then(
        (m) => m.RelocationAndReleaseModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'regulars',
    loadChildren: () =>
      import('./features/regular-account/regular-account.module').then(
        (m) => m.RegularAccountModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'criminal-cases',
    loadChildren: () =>
      import('./features/criminal-cases/criminal-case.module').then(
        (m) => m.CriminalCaseModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'civil-cases',
    loadChildren: () =>
      import('./features/civil-cases/civil-case.module').then(
        (m) => m.CivilCaseModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'international-cases',
    loadChildren: () =>
      import('./features/international-cases/international-cases.module').then(
        (m) => m.InternationalCasesModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'administration-cases',
    loadChildren: () =>
      import('./features/administration-cases/administration-cases.module').then(
        (m) => m.AdministrationCasesModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'hyperlink/:legalCaseId',
    loadChildren: () =>
      import('./features/hyper-link-resolver/hyper-link-resolver.module').then(
        (m) => m.HyperLinkResolverModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'accountancy',
    loadChildren: () =>
      import('./features/accountancy/accountancy.module').then(
        (m) => m.AccountancyModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'searcher',
    loadChildren: () =>
      import('./features/searcher/searcher.module').then(
        (m) => m.SearcherModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'strategies',
    loadChildren: () =>
      import('./features/strategy/strategy.module').then(
        (m) => m.StrategyModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('./features/manage/manage.module').then(
        (m) => m.ManageModule
      ),
    // canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'unauthorized-access',
    loadChildren: () =>
      import('./features/unauthorized-access/unauthorized-access.module').then(
        (m) => m.UnauthorizedAccessModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./features/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
