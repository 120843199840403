import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {WebsocketService} from './websocket.service';
import {INewRemark} from '../../shared/model/interface/new-remark.model.interface';
import {IRemark, IRemarkList} from '../../shared/model/interface/remark.model.interface';
import {map} from 'rxjs/operators';
import {RemarkList} from '../../shared/model/remark.model';
import {RemarkObservableMapper} from '../../shared/mapper/remark-observable.mapper';
import {IRemarkSearch} from "../../shared/model/interface/remark-search.model.interface";
import {IBase64File} from "../../shared/model/interface/base64-file.model.interface";
import {HttpParamsFactory} from "../../shared/factory/http-params.factory";

@Injectable({
  providedIn: 'root'
})
export class RemarkService {
  private URL = environment.backendUrl;

  constructor(private http: HttpClient,
              private websocketService: WebsocketService,
              private remarkObservableMapper: RemarkObservableMapper) {
  }

  connectToWebsocket(legalCaseId: number) {
    const request = this.websocketService.subscribeToRemarks(legalCaseId);
    return this.remarkObservableMapper.fromWebsocketMessage(request);
  }

  create(legalCaseId: number, newRemark: INewRemark) {
    return this.http.post<void>(this.URL + `/legal-cases/${legalCaseId}/remarks/create`, newRemark);
  }

  getLegalCaseRemarks(legalCaseId: number) {
    return this.http.get<IRemarkList>(this.URL + `/legal-cases/${legalCaseId}/remarks`)
      .pipe(
        map(data => new RemarkList(data))
      );
  }

  export(iRemarkSearch: IRemarkSearch) {
    return this.http.get<IBase64File>(this.URL + '/remarks', {
      params: HttpParamsFactory.notNullsFromObject(iRemarkSearch)
    });
  }
}
