import {Injectable} from '@angular/core';
import {IImportLegalCases} from '../../shared/model/interface/import-legal-cases.model.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IBase64File} from '../../shared/model/interface/base64-file.model.interface';
import {IEpu} from '../../shared/model/interface/epu-import.interface';
import {IVerification} from '../../shared/model/interface/verification-import.interface';
import {IBailiffImport} from '../../shared/model/interface/bailiff-import.interface.model';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {
  private readonly URL = environment.importerUrl;

  constructor(private _http: HttpClient) {
  }

  importLegalCases(lessorId: number, payload: IImportLegalCases) {
    return this._http.post(this.URL + `/lessors/${lessorId}/legal-cases`, payload);
  }

  importBalance(lessorId: number, payload: IBase64File) {
    return this._http.post(this.URL + `/lessors/${lessorId}/balance`, payload);
  }

  importValuations(lessorId: number, payload: IBase64File) {
    return this._http.post(this.URL + `/lessors/${lessorId}/valuations`, payload);
  }

  importGps(payload: IBase64File) {
    return this._http.post(this.URL + '/items/gps', payload)
  }

  importEpu(payload: IEpu) {
    return this._http.post(this.URL + '/epu/import', payload)
  }

  importVerification(payload: IVerification) {
    return this._http.post(this.URL + '/verification', payload)
  }

  importBailiff(payload: IBailiffImport) {
    return this._http.post(this.URL + '/bailiff', payload)
  }
}
