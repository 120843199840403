import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ICourt, ICourtList} from '../../shared/model/interface/court.model.interface';
import {IObjectList} from '../../shared/model/interface/object-list.model.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourtService {
  private static readonly _URL = environment.backendUrl + '/courts';

  constructor(private _httpClient: HttpClient) {
  }

  fetchAll(): Observable<ICourtList> {
    return this._httpClient.get<IObjectList<ICourt>>(CourtService._URL)
      .pipe(
        map(data => new ICourtList(data))
      );
  }

  search(name: string, page: number, size: number) {
    return this._httpClient.get<IObjectList<ICourt>>(CourtService._URL, {params: {
        name,
        page,
        size
      }})
      .pipe(
        map(data => new ICourtList(data))
      );
  }

  fetchOne(courtId: number) {
    return this._httpClient.get<ICourt>(CourtService._URL + `/${courtId}`);
  }
}
