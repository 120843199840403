import {ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IRemarkSearch} from "../../model/interface/remark-search.model.interface";
import {RemarkService} from "../../../core/services/remark.service";
import {RequestProcessor} from "../../utils/request-processor";

@Component({
  selector: 'app-remark-searcher-dialog',
  templateUrl: './remark-searcher-dialog.component.html',
  styleUrls: ['./remark-searcher-dialog.component.scss']
})
export class RemarkSearcherDialogComponent implements OnInit {
  @ViewChild('view') searcherView;

  @HostListener('keydown.enter', ['$event']) onKeydownEnter() {
    if (this.formValid) {
      this.export(this.form.value)
    }
  }

  get form() {
    return this.searcherView?.searchFormGroup
  }

  get formValid() {
    return this.valid;
  }

  valid = false;
  data;

  constructor(private matDialogRef: MatDialogRef<RemarkSearcherDialogComponent>,
              private remarkService: RemarkService,
              private requestProcessor: RequestProcessor,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this._updateValidity();
    this.form.valueChanges.subscribe(() => {
      this._updateValidity();
    })
  }

  export(form) {
    const request = this.remarkService.export(this._createSearchParams(form));
    this.requestProcessor.saveResponseAsFile(request);
  }

  private _updateValidity() {
    this.valid = this.form.valid;
    this.cdr.detectChanges();
  }

  private _createSearchParams(form) {
    return {
      createdAfter: form.createdAt?.from.format('YYYY-MM-DD'),
      createdBefore: form.createdAt?.to.format('YYYY-MM-DD'),
      legalCaseCreatedAfter: form.legalCaseCreatedAt?.from.format('YYYY-MM-DD'),
      legalCaseCreatedBefore: form.legalCaseCreatedAt?.to.format('YYYY-MM-DD'),
      vindicatorId: form.vindicatorId,
      instructingGroupId: form.instructingGroupId,
      fieldAttorneyId: form.fieldAttorneyId,
      instructingId: form.instructingId,
      debtorNIP: form.debtorNIP,
      debtorCoreId: form.debtorCoreId,
      debtorNumber: form.debtorNumber
    } as IRemarkSearch
  }

}
