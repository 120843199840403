import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export interface IBailiff {
  id: number;
  name: string;
}


export class IBailiffList extends ObjectList<IBailiff, IBailiff> {
  protected mapToListElement(instance: IBailiff): IBailiff {
    return instance;
  }

  protected mapToSelectableItem(instance: IBailiff): ISimpleSelectableData {
    return {
      label: instance.name,
      value: instance.id
    };
  }
}
