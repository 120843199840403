import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-remark-searcher-view',
  templateUrl: './remark-searcher-view.component.html',
  styleUrls: ['./remark-searcher-view.component.scss']
})
export class RemarkSearcherViewComponent implements OnInit {
  @Input() title;
  searchFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  private createForm() {
    this.searchFormGroup = this.formBuilder.group({
      createdAt: null,
      legalCaseCreatedAt: null,
      vindicatorId: null,
      fieldAttorneyId: null,
      instructingId: null,
      instructingGroupId: null,
      debtorNIP: null,
      debtorCoreId: null,
      debtorNumber: null
    });
  }
}
